export const radiobuttonOptions = [
  { value: 'people', label: 'In store' },
  { value: 'screen', label: 'Video call' },
  { value: 'phone_portrait', label: 'Phone call' },
];

export const radiobuttonYESNOOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' }
];

export const subjectOptions = [
  { value: 'High Jewelry', label: 'High Jewelry' },
  { value: 'Jewelry and Watches', label: 'Jewelry and Watches' },
  { value: 'Engagement and wedding ring', label: 'Engagement and wedding ring' },
  { value: 'Offer the perfect gift', label: 'Offer the perfect gift' },
  { value: 'Repair of a creation', label: 'Repair of a creation' },
  { value: 'Other services', label: 'Other services' }
];

export const clientTitleOptions = [
  { value: 'Mr', label: 'Mr' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Ms', label: 'Ms' },
  { value: 'I prefer not to say', label: 'I prefer not to say' }
];

export const getLabelFromValue = value => {
  const option = radiobuttonOptions.find(option => option.value === value);
  return option ? option.label : '-';
};

export const countryCodeIndex = {
  fr: 'France',
  gb: 'Great Britain',
  hk: 'Hong Kong',
  ae: 'United Arab Emirates',
  au: 'Australia',
  at: 'Austria',
  be: 'Belgium',
  de: 'Germany',
  fi: 'Finland',
  ie: 'Ireland',
  it: 'Italy',
  lu: 'Luxembourg',
  nl: 'Netherlands',
  pt: 'Portugal',
  dk: 'Denmark',
  no: 'Norway',
  se: 'Sweden',
  ch: 'Switzerland',
  es: 'Spain',
  nz: 'New-Zealand',
  jp: 'Japan',
  kr: 'Korea',
  sg: 'Singapore',
  tw: 'Taiwan',
  cn: 'China',
  kw: 'Koweit',
  qa: 'Qatar',
  sa: 'Saudi Arabia',
  ru: 'Russia',
  us: 'USA',
  ca: 'Canada',
  mc: 'Monaco',
  mo: 'Macau SAR',
  my: 'Malaysia'
};

export const optionsTranslations = [
  { value: 'en_US', label: 'English' },
  { value: 'fr', label: 'French' },
  { value: 'ja', label: 'Japanese' },
  { value: 'zh_TW', label: 'Chinese (Traditional)' },
  { value: 'zh_CN', label: 'Chinese (Simplified)' },
  { value: 'ko', label: 'Korean' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'ar', label: 'عربى' }
];

export const langMappings = {
  'cht': 'zh_TW',
  'zh': 'zh_CN',
  'kr': 'ko'
};

export const privacyPolicyUrls = {
  "fr": "https://www.chaumet.com/fr_fr/donnees-personnelles",
  "en_Us": "https://www.chaumet.com/gb_en/privacy-policy",
  "ja": "https://www.chaumet.com/jp_ja/privacy-policy",
  "ko": "https://www.chaumet.com/kr_kr/privacy-policy",
  "zh_CN": "https://www.chaumet.cn/cn_zh/privacy-policy",
  "zh_TW": "https://www.chaumet.com/hk_cht/privacy-policy",
  "zh-Hant-TW": "https://www.chaumet.com/tw_cht/privacy-policy",
  "ar": "https://www.chaumet.com/ae_ar/privacy-policy",
  "kr": "https://www.chaumet.com/kr_kr/privacy-policy",
  "default": "https://www.chaumet.com/gb_en/privacy-policy"
};

export const CAPTCHA_PUBLIC_KEY = '6Lf_yyQoAAAAABLm1IFjDUK2rnDmwdVskAi9oLGX';

